<template>
    <div>
        <label class="textSmall label">Temperatura</label>
        <div class="thermometerArea">
            <div class="thermometerFill" :style="{height: covertPercentToFillThermometer(temp), backgroundColor: currentColor.color}">
                <label class="tempTextPosition textSmall">{{Number(temp).toFixed(1)}} °C</label>
            </div>
            <div class="thermometerFillBottom" :style="{backgroundColor: currentColor.color}"></div>
        </div>
        <div class="thermometerArea thermometerIcon"></div>
    </div>
</template>

<script>
import gsap from 'gsap'

export default {
    props:{
        temp: String
    },
    methods:{
        covertPercentToFillThermometer(temp){
            let t = Number(temp)
            if(t > 85){
                t = 85
            }else if(t < 30){
                t = 30
            }
            
            if(t >= 75){
                this.currentColor = this.colors.danger
            }else if(t < 75 && t > 65){
                this.currentColor = this.colors.warning
            }else if (t <= 65){
                this.currentColor = this.colors.safe
            }
            return t + '%'
        },
    },
    watch: {
        temp(n) {
            gsap.to(this, { duration: 0.5, tweened: Number(n) || 0 })
        }
    },
    data(){
        return{
            tweened: 0,
            colors: {safe: { color: '#4375EF'}, warning: { color: '#ffc22d'}, danger: {color: '#ff3c3c'}},
            currentColor: {color: '#4375EF'}
        }
    }
}
</script>

<style>
    .tempTextPosition{
        position: absolute;
        white-space: nowrap;
        right: -75px;
        top: -10px
    }
    .thermometerArea{
        position: absolute;
        width: 53px;
        height: 165px;
        top: 30px;
        bottom: 0;
        right: 50px;
        left: 0;
        margin: auto auto;
    }
    .thermometerIcon{
        background-image: url("~@/assets/icons/dashboard/thermometer.svg");
        background-size: auto 100%;
        background-repeat: no-repeat
    }
    .thermometerFill{
        position: absolute;
        right: 0;
        left: 0;
        bottom: 10px;
        margin: 0 auto;
        height: 30%;        /* 30 ~ 85 */
        width: 50%;
        border-radius: 25%;
        transition: height .3s ease-in-out, background-color .6s linear;
    }
    .thermometerFillBottom{
        position: absolute;
        bottom: 5px;
        right: 0;
        left: 0;
        margin: 0 auto;
        height: 45px;
        width: 90%;
        border-radius: 100%;
        transition: background-color .6s linear;
    }
    @media(max-height: 900px ){
        .thermometerArea{
            width: 45px;
            height: 145px
        }
        .thermometerFillBottom{
            height: 40px;
        }
    }
    @media(max-width: 1450px){
        .tempTextPosition{
            right: -60px;
            top: -8px
        }
    }
    @media(max-width: 1450px), (max-height: 820px ){
        .thermometerArea{
            width: 40px;
            height: 125px
        }
        .thermometerFillBottom{
            height: 35px;
        }
    }
    @media(max-height: 750px ){
        .thermometerArea{
            width: 30px;
            height: 95px
        }
        .thermometerFillBottom{
            height: 22px;
        }
    }
    @media(max-width: 825px){
        .thermometerArea{
            right: 45px;
        }
        .tempTextPosition{
            right: -55px;
            top: -5px
        }
    }
    @media(max-width: 825px), (max-height: 660px ){
        .thermometerArea{
            width: 25px;
            height: 85px;
            top: 25px
        }
        .thermometerFillBottom{
            height: 18px;
        }
    }
</style>