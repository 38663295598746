<template>
    <div class="card" @click="handleClick">
        <label class="textSmall label">{{label}}</label>
        <div class="areaGraph">
            <section class="roundGraph">
                <svg class="circle-chart" viewBox="-5 5 45 45">
                    <circle class="circle-chart__background" stroke="#36393F" stroke-width="5" stroke-dasharray="100,100" stroke-linecap="round" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                    <circle class="circle-chart__circle" :stroke="color" stroke-width="5" :style="{stroke: colorIdle}" :stroke-dasharray="percent + ', 100'" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />
                </svg>
                <transition name="slide-fade">
                    <div v-if="animation && count != 0" class="textTotalValueArea">
                        <div class="textNormal textTotalValue">{{tweened.toFixed(0)}}</div>
                    </div>
                </transition>
            </section>
        </div>
        <div class="areaGraphCheck">
            <svg v-if="count == 0" class="checkmark" viewBox="2 0 52 52">
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </svg>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap'

export default {
    props:{
        label: String,
        color: String,
        colorIdle: String,
        percent: Number,
        count: Number
    },
    methods: {
        handleClick() {
            this.$emit('click')
        }
    },
    watch: {
        count(){
            this.value = this.count
        },
        value(n) {
            gsap.to(this, { duration: 0.5, tweened: Number(n) || 0 })
        }
    },
    mounted(){
        this.value = this.count
        this.animation = true
    },
    data() {
        return {
            value: 0,
            tweened: 0,
            animation: false
        }
    },
}
</script>

<style>
    .card{
        flex: 1;
        position: relative;
        padding-top: 10px;
        margin-left: 10px;
        background-color: #1a1a22;
        cursor: pointer
    }
    .label{
        margin-left: 10px;
    }
    .areaGraph{
        position: absolute;
        width: 150px;
        height: 150px;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto
    }
    .areaGraphCheck{
        position: absolute;
        width: 100px;
        height: 100px;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto
    }
    .roundGraph{
        position: absolute
    }
    .textTotalValueArea{
        position: absolute;
        display: grid;
        align-items: center;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .textTotalValue{
        align-self: center;
        text-align: center;
    }
    .circle-chart{
        width: 150px;
        height: 150px;
    }
    .circle-chart__circle {
        animation: circle-chart-fill 1.2s reverse;
        transform: rotate(-90deg);
        transform-origin: center;
        transition: .3s;
    }
    .circle-chart__background {
        transform-origin: center;
        transform: rotate(-90deg);
    }
    .circle-chart__info {
        animation: graphTxt 1.2s forwards;
        opacity: 0;
        transform: translateY(4px);
    }
    @keyframes circle-chart-fill {
        to { stroke-dasharray: 0 100; }
    }
    @keyframes graphTxt {
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
    .checkmark {
        position: absolute;
        right: 0;
        left: 0;
        width: 100px;
        height: 100px;
        stroke-width: 4;
        stroke: #fff;
        margin: 0 auto;
    }
    .checkmark__check {
        transform-origin: 50% 50%;
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        animation: stroke 0.8s cubic-bezier(0.65, 0, 0.45, 1) 0.3s forwards;
    }
    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }
    .slide-fade-enter-active{
        transition: all .5s ease;
    }
    .slide-fade-enter{
        transform: translateY(20px);
        opacity: 0;
    }
    @media(max-width: 1450px ), (max-height: 820px ){
        .card{
            padding-top: 5px;
            margin-left: 5px;
        }
        .label{
            margin-left: 5px;
        }
        .areaGraph{
            width: 120px;
            height: 120px;
        }
        .circle-chart{
            width: 120px;
            height: 120px;
        }
        .areaGraphCheck{
            width: 70px;
            height: 70px;
        }
        .checkmark {
            width: 70px;
            height: 70px;
        }
        .slide-fade-enter{
            transform: translateY(10px);
            opacity: 0;
        }
    }
    @media(max-width: 825px), (max-height: 660px ){
        .areaGraph{
            width: 90px;
            height: 90px;
        }
        .circle-chart{
            width: 90px;
            height: 90px;
        }
        .checkmark {
            width: 50px;
            height: 50px;
        }
        .areaGraphCheck{
            width: 50px;
            height: 50px;
        }
        .checkmark {
            width: 50px;
            height: 50px;
        }
    }
</style>