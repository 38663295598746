<template>
    <div class="ResponsiveContentBoard">
       <div class="areaStatus">
            <div class="areaZones">
                <label class="textSmall label" style="position: absolute">Plantas</label>
                <div class="countItem">
                    <div class="countText">{{tweenedZone.toFixed(0)}}</div>
                </div>
                <RoundGraph @click="setCurrentTab(tabs.fire)" :label="'Fogo'" :colorIdle="fillColorFireZone" :color="'#ff3c3c'" :percent="percentFillFireZone" :count="countFireZone"></RoundGraph>
                <RoundGraph @click="setCurrentTab(tabs.failure)" :label="'Falha'" :colorIdle="fillColorFailureZone" :color="'#ffc22d'" :percent="percentFillFailureZone" :count="countFailureZone"></RoundGraph>
            </div>
            <div class="areaDevices">
                <div class="areaDevicesTop">
                    <label class="textSmall label" style="position: absolute">Dispositivos</label>
                    <div class="countItem">
                        <div class="countText">{{tweenedDevices.toFixed(0)}}</div>
                    </div>
                    <RoundGraph @click="setCurrentTab(tabs.fire)" :label="'Fogo'" :colorIdle="fillColorFire" :color="'#ff3c3c'" :percent="percentFillFireDevice" :count="countFireDevice"></RoundGraph>
                    <RoundGraph @click="setCurrentTab(tabs.failure)" :label="'Falha'" :colorIdle="fillColorFailure" :color="'#ffc22d'" :percent="percentFillFailureDevice" :count="countFailureDevice"></RoundGraph>
                </div>
                <div class="areaDevicesBottom">
                    <RoundGraph @click="setCurrentTab(tabs.battery)" :label="'Bateria Fraca'" :colorIdle="fillColorBattery" :color="'#4375EF'" :percent="percentFillBatteryDevice" :count="countBatteryDevice"></RoundGraph>
                    <RoundGraph @click="setCurrentTab(tabs.supervision)" :label="'Supervisão'" :colorIdle="fillColorSupervision" :color="'#ff7700'" :percent="percentFillSupervisionDevice" :count="countSupervisionDevice"></RoundGraph>
                    <RoundGraph @click="setCurrentTab(tabs.disable)" :label="'Desabilitação'" :colorIdle="fillColorDisabled" :color="'#666666'" :percent="percentFillDisabledDevice" :count="countDisabledDevice"></RoundGraph>
                </div>
            </div>
        </div>
        <div class="areaCentralInfo">
           <div class="areaInfo">
                <div class="areaInfoRow rowTopPadding">
                    <div class="areaInfoCard" style="flex: 0 37.5%;">
                        <Gauge :label="'CPU'" :percent="CPU"></Gauge>
                    </div>
                    <div class="areaInfoCard" style="flex: 0 37.5%;">
                        <Gauge :label="'RAM'" :percent="MEM"></Gauge>
                    </div>
                    <div class="areaInfoCard" style="flex: 0 25%;">
                        <Thermometer :temp="temp"></Thermometer>
                    </div> 
                </div>
                <div class="areaInfoRow rowMiddlePadding">
                    <div class="areaInfoCard" style="flex: 0 40%;">
                        <label class="textSmall label" style="position: absolute">Alimentação</label>
                        <div class="redeStatus">
                            <img class="iconStatus" :src="currentBattery" alt="">
                            <div v-if="percentBattery" class="percentBattery textSmall">{{percentBattery}}</div>
                        </div>
                        <div class="redeStatus">
                            <img class="iconStatus" :src="currentPlug" alt="">
                        </div>
                        <label class="labelBottom textSmall">{{baterryStatus}}</label>
                    </div>
                    <div class="areaInfoCard" style="flex: 0 60%;">
                        <label class="textSmall label" style="position: absolute">Rede</label>
                        <div class="redeStatus">
                            <img class="iconStatus" :src="currentEthernetIcon" alt="">
                            <label class="textSmall labelIcon">Ethernet</label>
                        </div>
                        <div class="redeStatus">
                            <img class="iconStatus" :src="currentWifiIcon" alt="">
                            <label class="textSmall labelIcon">Wi-Fi</label>
                        </div>
                        <div class="redeStatus">
                            <img class="iconStatus" :src="currentMobileData" alt="">
                            <label class="textSmall labelIcon">Dados Moveis</label>
                        </div>
                    </div>
                </div>
                <div class="areaInfoRow rowBottomPadding">
                    <div class="areaInfoCard" style="flex: 0 45%;">
                        <label class="textSmall label">Armazenamento</label>
                        <ProgressBar :value="HardDisk"></ProgressBar>
                    </div>
                    <div class="areaInfoCard" style="flex: 0 25%;">
                        <label class="textSmall label">Central Fechada</label>
                        <img class="iconStatus" :src="currentTamperSwitchIcon" alt="">
                    </div>
                    <div class="areaInfoCard" style="flex: 0 30%;">
                        <label class="textSmall label">Software</label>
                        <div v-if="device.systemInfo != null" class="softwareInfo">
                            <div class="labelInfo textVerySmall">Versão:</div>
                            <div class="textVerySmall">{{device.systemInfo[SYSTEM_INFO_INDEX.CENTRAL].backEnd}}</div>
                            <div class="labelInfo textVerySmall">Database:</div>
                            <div class="textVerySmall">{{device.systemInfo[SYSTEM_INFO_INDEX.CENTRAL].dataBase}}</div>
                            <div class="labelInfo textVerySmall">Coordenador:</div>
                            <div class="textVerySmall">{{device.systemInfo[SYSTEM_INFO_INDEX.CENTRAL].coordinator}}</div>
                            <div class="labelInfo textVerySmall">Serial Number:</div>
                            <div class="textVerySmall">{{formatHardwareID(hardwareId)}}</div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {STATUS_COUNT_INDEX, SYSTEM_INFO_INDEX, NETWORK_STATUS} from '@/sys/Model/Device/Device';
    import RoundGraph from '@/views/content/Home/DeviceAccess/DashBoard/Widget/RoundGraph.vue'
    import Gauge from '@/views/content/Home/DeviceAccess/DashBoard/Widget/Gauge.vue'
    import Thermometer from '@/views/content/Home/DeviceAccess/DashBoard/Widget/Thermometer.vue'
    import ProgressBar from '@/views/content/Home/DeviceAccess/DashBoard/Widget/ProgressBar.vue'
    import gsap from 'gsap'

    export default {
        components: { RoundGraph, Gauge, Thermometer, ProgressBar},
        props: {
            device:{
                type: Object,
                default: null
            },
        },
        methods:{
            formatHardwareID(hardwareId){
                if(hardwareId.length > 16){
                    return '...' + hardwareId.substr(-16)
                }else{
                    return hardwareId
                }
            },
            setCurrentTab(tabId){
                this.$emit('setCurrentTab', tabId)
            }
        },
        watch:{
            countDevices(n) {
                gsap.to(this, { duration: 0.5, tweenedDevices: Number(n) || 0 })
            },
            countZone(n) {
                gsap.to(this, { duration: 0.5, tweenedZone: Number(n) || 0 })
            },
            $props:{
                handler(){
                    //zones
                    this.countZone = this.device.status.counters[STATUS_COUNT_INDEX.ZONE_COUNT]
                    //fire
                    this.countFireZone = this.device.status.counters[STATUS_COUNT_INDEX.ZONES_ON_FIRE];
                    this.percentFillFireZone = this.countFireZone > 0 ? ((this.countFireZone / this.countZone) * 100) : 100
                    this.fillColorFireZone = this.countFireZone > 0 ? null : '#29CB72'
                    //failure
                    this.countFailureZone = this.device.status.counters[STATUS_COUNT_INDEX.ZONES_ON_FAILURE];
                    this.percentFillFailureZone = this.countFailureZone > 0 ? ((this.countFailureZone / this.countZone) * 100) : 100
                    this.fillColorFailureZone = this.countFailureZone > 0 ? null : '#29CB72'

                    //devices
                    this.countDevices = this.device.status.counters[STATUS_COUNT_INDEX.DEVICE_COUNT]
                    //fire
                    this.countFireDevice = this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_FIRE]
                    this.percentFillFireDevice = this.countFireDevice > 0 ? ((this.countFireDevice / this.countDevices) * 100) : 100
                    this.fillColorFire = this.countFireDevice > 0 ? null : '#29CB72'
                    //failure
                    this.countFailureDevice = this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_FAILURE]
                    this.percentFillFailureDevice = this.countFailureDevice > 0 ?((this.countFailureDevice/this.countDevices) * 100) : 100
                    this.fillColorFailure = this.countFailureDevice > 0 ? null : '#29CB72'
                    //BatteryLow
                    this.countBatteryDevice = this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_LOW_BATTERY];
                    this.percentFillBatteryDevice = this.countBatteryDevice > 0 ? ((this.countBatteryDevice / this.countDevices) * 100) : 100
                    this.fillColorBattery = this.countBatteryDevice > 0 ? null : '#29CB72'
                    //Supervision
                    this.countSupervisionDevice = this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_SUPERVISION];
                    this.percentFillSupervisionDevice = this.countSupervisionDevice > 0 ? ((this.countSupervisionDevice/this.countDevices) * 100) : 100
                    this.fillColorSupervision = this.countSupervisionDevice > 0 ? null : '#29CB72'
                    //Disable
                    this.countDisabledDevice = this.device.status.counters[STATUS_COUNT_INDEX.DEVICES_ON_DISABLE]
                    this.percentFillDisabledDevice = this.countDisabledDevice > 0 ? ((this.countDisabledDevice/this.countDevices) * 100) : 100
                    this.fillColorDisabled = this.countDisabledDevice > 0 ? null : '#29CB72'

                    //Software
                    this.hardwareId = this.device.hardwareId

                    if(!this.device.systemInfo) { return }
                    //central
                    //cpu, memory, temperature
                    if(!this.initInfo){
                        setTimeout(() => {
                            this.MEM = this.device.systemInfo[SYSTEM_INFO_INDEX.RAM_USAGE]
                            this.CPU = this.device.systemInfo[SYSTEM_INFO_INDEX.CPU_USAGE]
                            this.temp = this.device.systemInfo[SYSTEM_INFO_INDEX.CORE_TEMPERATURE]
                            this.HardDisk = this.device.systemInfo[SYSTEM_INFO_INDEX.HD_USAGE]
                            this.initInfo = true
                        }, 600);
                    }else{
                        this.MEM = this.device.systemInfo[SYSTEM_INFO_INDEX.RAM_USAGE]
                        this.CPU = this.device.systemInfo[SYSTEM_INFO_INDEX.CPU_USAGE]
                        this.temp = this.device.systemInfo[SYSTEM_INFO_INDEX.CORE_TEMPERATURE]
                        this.HardDisk = this.device.systemInfo[SYSTEM_INFO_INDEX.HD_USAGE]
                    }
                    //supply
                    
                    if(this.device.systemInfo[SYSTEM_INFO_INDEX.BATTERY].batteryMode == 0){
                        this.currentBattery = require("@/assets/icons/dashboard/baterry.svg")
                        this.baterryStatus = 'Conectado na rede AC'
                        this.percentBattery = null
                    }else if (this.device.systemInfo[SYSTEM_INFO_INDEX.BATTERY].batteryMode == 1){
                        this.currentBattery = require("@/assets/icons/dashboard/baterryOn.svg")
                        this.baterryStatus = 'Falha na alimentação da rede AC'
                        this.percentBattery = this.device.systemInfo[SYSTEM_INFO_INDEX.BATTERY].percentual + '%'
                    }else{
                        this.currentBattery = require("@/assets/icons/dashboard/baterryOff.svg")
                        this.baterryStatus = 'Falha na bateria'
                        this.percentBattery = null
                    }
                    this.currentPlug = this.device.systemInfo[SYSTEM_INFO_INDEX.BATTERY].plug ? this.supplyStatusIcon.plug.on : this.supplyStatusIcon.plug.off
                    
                    //rede
                    this.currentEthernetIcon = this.device.systemInfo[SYSTEM_INFO_INDEX.ETHERNET].status == NETWORK_STATUS.ON ? this.redeStatusIcons.ethernet.on : this.redeStatusIcons.ethernet.off
                    this.currentWifiIcon = this.device.systemInfo[SYSTEM_INFO_INDEX.WIFI].status == NETWORK_STATUS.ON ? this.redeStatusIcons.wifi.on : this.redeStatusIcons.wifi.off
                    this.currentMobileData = this.device.systemInfo[SYSTEM_INFO_INDEX.MOBILLE].status == NETWORK_STATUS.ON ? this.redeStatusIcons.mobileData.on : this.redeStatusIcons.mobileData.off
                    //tamperSwitch
                    if(this.device.systemInfo[SYSTEM_INFO_INDEX.TAMPER_SWITCH]){
                        this.currentTamperSwitchStatus = this.tamperSwitchIcons.open.status
                        this.currentTamperSwitchIcon = this.tamperSwitchIcons.open.icon
                    }else{
                        this.currentTamperSwitchStatus = this.tamperSwitchIcons.close.status
                        this.currentTamperSwitchIcon = this.tamperSwitchIcons.close.icon
                    }
                },
                deep:true,
                immediate:true
            },
        },
        data() {
            return {
                countZone: 0,
                tweenedZone: 0,
                countDevices: 0,
                tweenedDevices: 0,
                percentFillFireZone: 100,
                percentFillFailureZone: 100,
                fillColorFireZone: null,
                fillColorFailureZone: null,
                countFireZone: 0,
                countFailureZone: 0,
                percentFillFireDevice: 100,
                percentFillFailureDevice: 100,
                percentFillBatteryDevice: 100,
                percentFillSupervisionDevice: 100,
                percentFillDisabledDevice: 100,
                fillColorFire: null,
                fillColorFailure: null,
                fillColorBattery: null,
                fillColorSupervision: null,
                fillColorDisabled: null,
                countFireDevice: 0,
                countFailureDevice: 0,
                countBatteryDevice: 0,
                countSupervisionDevice: 0,
                countDisabledDevice: 0,
                MEM: 0,
                CPU: 0,
                temp: '0',
                initInfo: false,
                tabs: { 
                    fire: {name: 'Fogo', id: 1, color: '#ff2a2a'}, 
                    failure: {name: 'Falha', id: 2, color: '#ffc22d'},
                    battery: {name: 'Bateria', id: 3, color: '#4375EF'},
                    supervision: {name: 'Supervisão', id: 4, color: '#ff7700'},
                    disable: {name: 'Desabilitação', id: 5, color: '#666666'}
                },
                redeStatusIcons: {
                    ethernet: {on: require("@/assets/icons/dashboard/ethernetOn.svg"), off: require("@/assets/icons/dashboard/ethernetOff.svg")},
                    wifi: {on: require("@/assets/icons/dashboard/wifiOn.svg"), off: require("@/assets/icons/dashboard/wifiOff.svg")},
                    mobileData: {on: require("@/assets/icons/dashboard/moveisOn.svg"), off: require("@/assets/icons/dashboard/moveisOff.svg")}
                },
                supplyStatusIcon: {
                    plug: {on: require("@/assets/icons/dashboard/plugOn.svg"), off: require("@/assets/icons/dashboard/plugOff.svg")},
                    /* battery: {modeBaterry: require("@/assets/icons/dashboard/baterryOn.svg"), failure: require("@/assets/icons/dashboard/baterryOff.svg"), carrying: require("@/assets/icons/dashboard/baterry.svg")} */
                },
                currentBattery: require("@/assets/icons/dashboard/baterry.svg"),
                currentPlug: require("@/assets/icons/dashboard/plugOn.svg"),
                baterryStatus: '',
                percentBattery: '',
                currentEthernetIcon: require("@/assets/icons/dashboard/ethernetOn.svg"),
                currentWifiIcon: require("@/assets/icons/dashboard/wifiOn.svg"),
                currentMobileData: require("@/assets/icons/dashboard/moveisOn.svg"),
                tamperSwitchIcons: { 
                    close: {icon: require("@/assets/icons/dashboard/padlockClose.svg"), status: 'Central Fechada'},
                    open: {icon: require("@/assets/icons/dashboard/padlockOpen.svg"), status: 'Central Aberta'}
                },
                HardDisk:0,
                currentTamperSwitchIcon: require("@/assets/icons/dashboard/padlockClose.svg"),
                currentTamperSwitchStatus: 'Central Fechada',
                STATUS_COUNT_INDEX: STATUS_COUNT_INDEX,
                SYSTEM_INFO_INDEX: SYSTEM_INFO_INDEX,
                hardwareId: null,
            }
        },
    }  
</script>

<style scoped>
    .percentBattery{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: 50px;
        height: 25px;
        color: #1e2227
    }
    .redeStatus{
        flex: 1;
        display: flex;
        position: relative;
        margin-left: 10px;
        text-align: center;
        padding-bottom: 5%
    }
    .iconStatus{
        position: absolute;
        width: 100px;
        top: 45%;
        left: 50%;
        margin-top: -50px;
        margin-left: -50px;
    }
    .labelIcon{
        white-space: nowrap;
        align-self: flex-end;
        width: 100%;
    }
    .areaStatus{
        position: absolute;
        left: 10px;
        top: 10px;
        bottom: 10px;
        width: 50%
    }
    .areaZones{
        display: flex;
        position: absolute;
        top: 0; 
        left: 0;
        right: 15px;
        height: 33%;
        padding: 10px 10px 10px 0px;
        background-color: #1e2227;
    }
    .countItem{
        display: table;
        flex: 1;
        height: 100%;
        margin-left: 10px
    }
    .countText{
        display: table-cell;
        height: 100%;
        padding: 10px;
        vertical-align: middle;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        color: rgb(175, 175, 175);
        font-size: 84px
    }
    .areaDevices{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 15px;
        height: 66%;
        background-color: #1e2227;
    }
    .areaDevicesTop{
        display: flex;
        height: 50%;
        padding: 10px 10px 5px 0px
    }
    .areaDevicesBottom{
        display: flex;
        height: 50%;
        padding: 5px 10px 10px 0px
    }
    .labelBottom{
        position: absolute;
        bottom: 13%;
        left: 0;
        width: 100%;
        text-align: center
    }
    .areaCentralInfo{
        position: absolute;
        right: 10px;
        top: 10px;
        bottom: 10px;
        width: 50%
    }
    .areaInfo{
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 15px;
        right: 0;
        background-color: #1e2227;
    }
    .areaInfoRow{
        flex: 1;
        display: flex;
        flex-direction: row
    }
    .rowTopPadding{
        padding: 10px 10px 5px 0px
    }
    .rowMiddlePadding{
        padding: 5px 10px 5px 0px
    }
    .rowBottomPadding{
        padding: 5px 10px 10px 0px
    }
    .areaInfoCard{
        display: flex;
        position: relative;
        padding-top: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        margin-left: 10px;
        background-color: #1a1a22
    }
    .label{
        white-space: nowrap;
        margin: 0 10px;
    }
    .softwareInfo{
        position: absolute;
        width: 90%;
        height: 80%;
        padding-top: 5px;
        bottom: 0;
        right: 5%;
        left: 5%;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .labelInfo{
        width: 100%;
        color: #808080;
    }
    @media(max-height: 900px), (max-width: 1600px){
        .iconStatus{
            width: 80px;
            margin-left: -40px;
            margin-top: -40px;
        }
    }
    @media(max-height: 820px), (max-width: 1450px){
        .iconStatus{
            width: 70px;
            margin-left: -35px;
            margin-top: -35px
        }
    }
    @media(max-height: 750px), (max-width: 825px){
        .iconStatus{
            width: 60px;
            margin-left: -30px;
            margin-top: -30px;
        }
        .countText{
            font-size: 60px
        }
    }
    @media(max-height: 660px){
        .redeStatus{
            padding-bottom: 0%;
        }
    }
    @media(max-width: 1450px){
        .areaStatus{
            left: 5px;
            top: 5px;
            bottom: 5px;
        }
        .areaZones{
            right: 7.5px;
            padding: 5px 5px 5px 0px;
        }
        .countItem{
            margin-left: 5px
        }
        .areaDevices{
            right: 7.5px;
        }
        .areaInfo{
            left:  7.5px;
        }
        .areaDevicesTop{
            padding: 5px 5px 2.5px 0px
        }
        .areaDevicesBottom{
            padding: 2.5px 5px 5px 0px
        }
        .areaCentralInfo{
            right: 5px;
            top: 5px;
            bottom: 5px;
        }
        .rowTopPadding{
            padding: 5px 5px 2.5px 0px
        }
        .rowMiddlePadding{
            padding: 2.5px 5px 2.5px 0px
        }
        .rowBottomPadding{
            padding: 2.5px 5px 5px 0px
        }
        .areaInfoCard{
            padding-top: 5px;
            margin-left: 5px
        }
        .label{
            margin: 0 5px
        }
    }
</style>