<template>
    <div>
        <label class="textSmall label">{{label}}</label>
        <div class="halfRingContainer">
            <div class="ring ringGreen"></div>
            <template v-if="label == 'RAM'">
                <div class="ring ringYellowMemory"></div>
                <div class="ring ringRedMemory"></div>
            </template>
            <template v-else>
                <div class="ring ringYellow"></div>
                <div class="ring ringRed"></div>
            </template>
            <svg class="areaArrow" viewBox="0 0 20 110" :style="{transform: 'rotate(' + convertPercentToValueFromArrow(tweened.toFixed(0)) + 'deg)'}">
                <polygon points="10, 0 5,95 10,110 15,95" style="fill:white" />
            </svg>
        </div>
        <label class="labelHalfRingBottom textAverage">{{ tweened.toFixed(0)}}%</label>
    </div>
</template>

<script>
import gsap from 'gsap'

export default {
    props:{
        label: String,
        percent: Number
    },
    created(){
        this.tweened = this.percent;
    },
    methods:{
        convertPercentToValueFromArrow(percent){
            return parseFloat((((190 * percent) / 100) - 95))
        },
    },
    watch: {
        percent(n) {
            gsap.to(this, { duration: 0.5, tweened: Number(n) || 0 })
        }
    },
    data() {
        return {
            tweened: 0
        }
    },
}
</script>

<style>
    .halfRingContainer {
    	height: 107.5px;
        width: 200px;
        top: 40px;
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto
    }
    .areaArrow {
        position: absolute;
        z-index: 4;
        left: 90px;
        transform-origin: center 90%;
        transition: transform .3s ease-in-out;
        transform: rotate(-95deg);
        height: 100%;
        width: 20px
    }
    .labelHalfRingBottom{
        position: absolute;
        bottom: 5%;
        left: 0;
        width: 100%;
        text-align: center
    }
    .ring{
        position: absolute;
        border-radius: 100%;
        width: 200px;
        height: 200px
    }
    .ringGreen {
    	border: 25px solid #29CB72;
        z-index: 1;
    }
	.ringYellow {
    	border: 25px solid transparent;
        border-left-color: #ffc22d;
        z-index: 2;
        transform: rotate(135deg);
    }
    .ringYellowMemory {
    	border: 25px solid transparent;
        border-left-color: #ffc22d;
        z-index: 2;
        transform: rotate(190deg);
    }
    .ringRed {
        border: 25px solid transparent;
        border-left-color: #ff2a2a;
        z-index: 3;
        transform: rotate(190deg);
    }
    .ringRedMemory{
        border: 25px solid transparent;
        border-left-color: #ff2a2a;
        z-index: 3;
        transform: rotate(210deg);
    }
    .arrow{
        position: absolute;
        z-index: 4;
        left: 90px;
        transform-origin: center 90%;
        transition: transform .3s ease-in-out;  
    }
    .arrowSize{
        height: 110px;
        width: 20px
    }
    @media(max-width: 1450px), (max-height: 820px ){
        .halfRingContainer {
            height: 80px;
            width: 150px;
        }
        .ring{
            width: 150px;
            height: 150px;
        }
        .ringGreen {
            border: 20px solid #29CB72;
        }
        .ringYellow {
            border: 20px solid transparent;
            border-left-color: #ffc22d;
            transform: rotate(135deg);
        }
        .ringYellowMemory {
            border: 20px solid transparent;
            border-left-color: #ffc22d;
            transform: rotate(190deg);
        }
        .ringRed {
            border: 20px solid transparent;
            border-left-color: #ff2a2a;
            transform: rotate(190deg);
        }
        .ringRedMemory{
            border: 20px solid transparent;
            border-left-color: #ff2a2a;
            transform: rotate(210deg);
        }
        .arrow{
            left: 65px;
            bottom: 0px;
            transform-origin: center 90%;
            transition: transform .3s ease-in-out;  
        }
        .areaArrow {
            left: 65px;
        }
    }
    @media(max-width: 825px), (max-height: 660px ){
        .halfRingContainer {
            height: 60px;
            width: 110px;
        }
        .ring{
            width: 110px;
            height: 110px;
        }
        .ringGreen {
            border: 15px solid #29CB72;
        }
        .ringYellow {
            border: 15px solid transparent;
            border-left-color: #ffc22d;
            transform: rotate(135deg);
        }
        .ringYellowMemory {
            border: 15px solid transparent;
            border-left-color: #ffc22d;
            transform: rotate(190deg);
        }
        .ringRed {
            border: 15px solid transparent;
            border-left-color: #ff2a2a;
            transform: rotate(190deg);
        }
        .ringRedMemory{
            border: 15px solid transparent;
            border-left-color: #ff2a2a;
            transform: rotate(210deg);
        }
        .areaArrow {
            left: 45px;
        }
    }
</style>