<template>
    <div>
        <div class="progressBarArea">
            <div class="barArea">
                <div class="bar textAverage" :style="{width: value + '%', backgroundColor: getCurrentColor()}">
                </div>
            </div>
        </div>
        <label class="labelBottom textAverage">{{value.toFixed(0)}}%</label>
    </div>
</template>

<script>
import gsap from 'gsap'

export default {
    props:{
        value: Number
    },
    methods:{
        getCurrentColor(){
            if(this.value >= 90){
                this.currentColor = this.colors.danger
            }else if(this.value < 90 && this.value > 80){
                this.currentColor = this.colors.warning
            }else if (this.value <= 80){
                this.currentColor = this.colors.safe
            }

            return this.currentColor.color
        }
    },
    watch: {
        value(n) {
            gsap.to(this, { duration: 0.5, tweened: Number(n) || 0 })
        }
    },
    data(){
        return{
            tweened: 0,
            colors: {safe: { color: '#4375EF'}, warning: { color: '#ffc22d'}, danger: {color: '#ff3c3c'}},
            currentColor: {color: '#4375EF'}
        }
    }
}
</script>

<style>
    .progressBarArea{
        position: absolute;
        height: 50px;
        width: 90%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto auto;
        background-color: #101018;
        border-radius: 4px 4px 4px 4px;
        box-shadow: inset 0px 25px 40px 0px rgba(0,0,0,0.6), 0px 3px 6px -5px rgba(255,255,255,1);
    }
    .barArea{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 4px;
    }
    .bar{
        position: absolute;
        height: 100%;
        width: 90%;
        background-color: #4375EF;
        border-radius: 3px 3px 3px 3px;
        transition: width .3s ease-in-out, background-color .6s linear;
    }
    .labelBottom{
        position: absolute;
        bottom: 5%;
        left: 0;
        width: 100%;
        text-align: center
    }
    @media(max-width: 1450px), (max-height: 820px ){
        .progressBarArea{
            height: 40px;
        }
    }
    @media(max-width: 825px), (max-height: 660px ){
        .progressBarArea{
            height: 30px;
        }
    }
</style>